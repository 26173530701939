import Anchor from '@/components/base/anchor';
import React from 'react';
import { IFooterPolicyProps } from './types';
import { motion } from 'framer-motion';

const animations = {
  container: {
    hidden: {
      opacity: 0,
      x: -100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delayChildren: 0.3,
        staggerChildren: 0.15,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  item: {
    hidden: {
      opacity: 0,
      x: -50,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const FooterPolicy: React.FC<IFooterPolicyProps> = ({ policy }) => {
  return (
    <nav className="footer-policy">
      <motion.ul
        className="policy-list"
        variants={animations.container}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
      >
        {policy?.map((item, index) => (
          <div key={index}>
            <motion.li
              key={index}
              className="policy-list-item"
              variants={animations.item}
            >
              <Anchor href={item.href}>{item.title}</Anchor>
            </motion.li>
            <motion.div className="policy-circle" variants={animations.item} />
          </div>
        ))}
      </motion.ul>
    </nav>
  );
};

export default FooterPolicy;
