import { functions } from '@wap-client/core';
import type { SpinnerProps } from './types';
import { clsx } from '@/utils';

const Spinner: React.FunctionComponent<SpinnerProps> = ({
  className,
  size = 'medium',
  centered,
  noMargin,
  ...rest
}) => {
  const classNames = functions.classnames('spinner', className);

  return (
    <div
      className={clsx(classNames, {
        [`spinner-${size}`]: size,
        'spinner-centered': centered,
        'spinner-no-margin': noMargin,
      })}
      {...rest}
    >
      <span className="loader"></span>
    </div>
  );
};

export default Spinner;
