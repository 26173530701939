import React, { useEffect, useRef } from 'react';
import { functions } from '@wap-client/core';
import { useCombineRefs } from '@wap-client/core';

import Anchor from '../anchor';

import type { ButtonProps } from './types';

const Button: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    anchor,
    appearance = 'default',
    autoFocus,
    children,
    className,
    iconAfter,
    iconBefore,
    isDisabled = false,
    isLoading = false,
    kind = 'standard',
    shape = 'square',
    size = 'medium',
    testId,
    type = 'button',
    ...rest
  },
  ref
) => {
  const buttonRef = useRef<HTMLButtonElement>();
  const refs = useCombineRefs<HTMLButtonElement>(buttonRef, ref);
  const classNames = functions.classnames(
    'button',
    `button--${kind}-${appearance}`,
    `button--shape-${shape}`,
    `button--size-${size}`,
    className
  );

  useEffect(() => {
    if (autoFocus && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [autoFocus]);

  const renderContent = () => {
    return (
      <>
        {children && <span className="button-text">{children}</span>}
        {isLoading && <span>Yükleniyor</span>}
      </>
    );
  };

  return anchor ? (
    <Anchor className={classNames} data-testid={testId} {...anchor} />
  ) : (
    <button
      ref={refs}
      className={classNames}
      data-testid={testId}
      type={type}
      disabled={isDisabled}
      tabIndex={isDisabled ? -1 : 0}
      {...rest}
    >
      {renderContent()}
    </button>
  );
};

export default React.forwardRef(Button);
