import Anchor from '@/components/base/anchor/Anchor';
import Icon from '@/components/base/icon';
import { ICardProfileProps } from './types';
import { useApp } from '@wap-client/core';
import useAuthStore from '@/context/useAuth';
import Link from 'next/link';

const CardProfile: React.FC<ICardProfileProps> = ({ description, title }) => {
  const app = useApp();

  const { authData } = useAuthStore();

  const name = authData?.user?.given_name || '';
  const surname = authData?.user?.family_name || '';

  const profile = app.settings.routes['my-profile'];

  return (
    <div className="card-profile">
      {authData?.isAuth ? (
        <>
          <span className="user-avatar">{name?.slice(0, 2).toUpperCase()}</span>

          <h5>{`${name} ${surname}`}</h5>

          <p>{app.settings.translations['auth_profile_footer_text']}</p>

          <Anchor href={`${profile?.href}`} className="card-profile-button">
            {profile?.title}
          </Anchor>
        </>
      ) : (
        <>
          <Icon name="icon-user-circle" size="xlarge" />

          <h5>{title}</h5>

          <p>{description}</p>

          <Link href={`/auth/register`} className="card-profile-button">
            {app.settings.translations['create_profile']}
          </Link>
        </>
      )}
    </div>
  );
};

export default CardProfile;
