import Anchor from '@/components/base/anchor/Anchor';
import { IFooterNavigationProps } from './types';
import { motion } from 'framer-motion';

const animations = {
  item: {
    hidden: {
      opacity: 0,
      x: -50,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const FooterNavigation: React.FC<IFooterNavigationProps> = ({
  navigation,
  navigation2,
}) => {
  if (!navigation) return null;

  return (
    <nav className="footer-navigation">
      <div className="footer-navigation-menu">
        <ul>
          {navigation.map((item, index) => (
            <li key={index}>
              <Anchor href={item.href} motion={{ variants: animations.item }}>
                {item.title}
              </Anchor>
            </li>
          ))}
        </ul>
        <motion.div className="line line-first" variants={animations.item} />
        <ul>
          {navigation2.map((item, index) => (
            <li key={index}>
              <Anchor href={item.href} motion={{ variants: animations.item }}>
                {item.title}
              </Anchor>
            </li>
          ))}
        </ul>
      </div>

      <motion.div className="line line-second" variants={animations.item} />
    </nav>
  );
};

export default FooterNavigation;
