import React from 'react';
import { useApp } from '@wap-client/core';
import Anchor from '@/components/base/anchor/Anchor';
import { IFooterProps } from './types';
import Button from '@/components/base/button/Button';
import Icon from '@/components/base/icon';
import Image from '@/components/base/image';
import FooterNavigation from './FooterNavigation';
import FooterPolicy from './FooterPolicy';
import { Container } from '@/components/base/gridview';
import SocialIconList from '../social-icon-list';
import { motion } from 'framer-motion';
import FooterSubscription from './FooterSubscription';
import CardProfile from '../card-profile';

const animations = {
  container: {
    hidden: {
      opacity: 0,
      x: -100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delayChildren: 0.5,
        staggerChildren: 0.25,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  item: {
    hidden: {
      opacity: 0,
      x: -50,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.2,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const Footer: React.FunctionComponent<IFooterProps> = ({
  navigation = [],
  navigation2 = [],
  policy = [],
  logo,
  profileDescription,
  profileTitle,
}) => {
  const app = useApp();

  const onClickScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <footer className="footer">
      <Container>
        <motion.div
          className="footer-content"
          variants={animations.container}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
        >
          <motion.div className="footer-logo" variants={animations.item}>
            <Anchor>
              <Image {...logo} />
            </Anchor>
          </motion.div>

          <div className="footer-sub-container">
            <div className="footer-left">
              <FooterNavigation
                navigation={navigation}
                navigation2={navigation2}
              />
              <SocialIconList className="footer-social" />
            </div>

            <div className="footer-sub-content">
              <FooterSubscription />
              <CardProfile
                description={profileDescription}
                title={profileTitle}
              />
            </div>
          </div>
        </motion.div>

        <motion.div
          className="footer-copy-group"
          variants={animations.container}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
        >
          <motion.div className="footer-copyright" variants={animations.item}>
            <span>{app.settings.translations['copyright']}</span>
            <span>{app.settings.translations['allright']}</span>
          </motion.div>
          <FooterPolicy policy={policy} />
        </motion.div>
      </Container>

      <Button className="scrollTop" onClick={onClickScrollTop}>
        <Icon name="icon-arrow-up" />
      </Button>
    </footer>
  );
};

export default Footer;
