import { SubscribeFormTypes } from '@/components/widgets/footer/types';
import { useApp } from '@wap-client/core';
import { queryService } from '@wap-client/services';
import { useState } from 'react';

export default function useSubscribe() {
  const app = useApp();

  const [formStatus, setFormStatus] = useState<SubscribeFormTypes>('default');
  const [loadingState, setloadingState] = useState(false);
  const [formValues, setFormValues] = useState<any>({});
  const [checkPrivacy, setCheckPrivacy] = useState<boolean>(false);

  const handleChange = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, email: evnt.target.value });
    setFormStatus('default');
  };

  const onSubmit = (evnt?: React.FormEvent<HTMLFormElement>) => {
    evnt?.preventDefault();

    if (!formValues.email) {
      return;
    }

    setloadingState(true);

    const requestBody = {
      parameters: null,
      columns: [
        {
          name: 'email',
          data: {
            refs: [],
            value: formValues.email,
          },
        },
        {
          name: 'title',
          data: {
            refs: [],
            value: 'Abone',
          },
        },
      ],
    };

    queryService
      .create(
        app.environment,
        {
          name: 'create-app-subscribes',
          language: app.language,
        },
        requestBody
      )
      .then((response) => {
        if (response) {
          setFormStatus('success');
          setTimeout(() => {
            setFormValues({
              email: '',
            });
            setFormStatus('default');
          }, 2000);
        }
        setloadingState(false);
      })
      .catch((error) => {
        if (error.code === 409) {
          setFormStatus('duplicate');
          setTimeout(() => {
            setFormValues({
              email: '',
            });
            setFormStatus('default');
          }, 2000);
        } else {
          setFormStatus('error');
        }
        setloadingState(false);
      });
  };

  return {
    formStatus,
    loadingState,
    checkPrivacy,
    setCheckPrivacy,
    onSubmit,
    setFormValues,
    formValues,
    setFormStatus,
    handleChange,
  };
}
