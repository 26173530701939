import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface IAuthData {
  isAuth?: boolean;
  token: string;
  refreshToken: string;
  user?: {
    sub: string;
    given_name: string;
    family_name: string;
    name: string;
    username: string;
    language: string;
    email: string;
    email_verified: boolean;
    gender: number;
    allowCommunication: boolean;
    allowNewsletter: boolean;
    allowWhatsAppCommunication: boolean;
    birthDate: string;
    city: string;
    cityId: string;
    country: string;
    countryId: string;
    phoneNumber: string;
    middlename: string;
    isNomandUser: boolean;
  };
}
interface AuthState {
  authData: IAuthData;
  setAuthData: (user: IAuthData) => void;
  logoutUser: () => void;
}

const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        authData: { token: '', isAuth: false } as IAuthData,
        setAuthData: (data: IAuthData) =>
          set((state) => ({
            ...state,
            authData: { ...data, isAuth: data.token ? true : false },
          })),
        logoutUser: () =>
          set((state) => ({
            ...state,
            authData: { token: '', isAuth: false } as IAuthData,
          })),
      }),
      { name: 'authStore' }
    )
  )
);

export default useAuthStore;
